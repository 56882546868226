import { useEffect, useState } from "react";
import "../../../styles/search.css";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import PathConstants from "../../../routes/PathConstants";
import RideService from "../../../Services/RideService";
import useService from "../../../hooks/useService";

import Select from "react-select";
import { toast } from "react-toastify";
import FiltersConstants from "../../../constants/FiltersConstants";
import AuthUser from "../AuthUser";
import RolesConstants from "../../../constants/RolesConstants";
import CoTravelService from "../../../Services/CoTravelService";
import { throwError } from "rxjs";
import UserProfileService from "../../../Services/UserProfileService";

export default function CoTravelCreateQuery() {
  const navigate = useNavigate();
  const location = useLocation();
  // const state = location.state ? location.state : {};
  const filtersConstants = FiltersConstants();
  const { user } = AuthUser();
  const [validated, setValidated] = useState(false);
  const [date, setDate] = useState();
  const [inputs, setInputs] = useState(
    // state && state.inputs ? state.inputs :
    {}
  );
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [toBeRedirected, setToBeRedirected] = useState(false);
  const [initialMsg, setInitialMsg] = useState(false);
  const [errors, setErrors] = useState({ from: {}, to: {}, date: {} });
  const [travelQuery, setTravelQuery] = useState();

  const coTravelService = useService(CoTravelService);
  const rideService = useService(RideService);
  const userProfileService = useService(UserProfileService);
  const reinitializeErrors = () => {
    setErrors({ from: {}, to: {}, date: {} });
    // setErrors(values => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    if (user && (user.role === RolesConstants.OPERATOR)) {
      navigate(PathConstants.HOME);
    } else {
      validateUserDetails();
    }

  }, []);
  const validateUserDetails = () => {
    if (!user.phone) {
      setToBeRedirected(true);
      return;
    }
    setPageLoading(true);
    userProfileService.myprofile().subscribe({
      next: response => {
        if (response.status !== 0) {
          setPageLoading(false);

          //validate here
          if (!response.result.age || !response.result.gender) {
            setToBeRedirected(true);
          }
        }
      },
    });
  }
  const navigateToProfileUpdate = () => {
    navigate(PathConstants.ACCOUNT);
  }
  const setInitialValues = event => {
    setLocations([]);
    setInitialMsg(true);
  };
  const searchLocation = event => {
    reinitializeErrors();
    if (event.altKey || event.ctrlKey || event.shiftKey) {
      return;
    }

    var value = event;//.target.value + event.key;
    setLoading(true);
    // return;
    // setInitialMsg(false);
    rideService.searchLocation(value).subscribe({
      next: response => {
        if (response.status !== 0) {
          var locations = [];
          response.result.map(location => {
            return locations.push({
              label: location.city,
              value: location.city,
              state: location.state,
              landmark: location.landmark,
              id: location._id,
            });
          });
          setLocations(locations);
          setLoading(false);
          setInitialMsg(false);
        }
      },
      error: err => {
        toast.dismiss();
        toast.error(err.message);
      },
    });
  };

  const notFoundMessage = event => {
    if (loading) {
      return `Loading`;
    } else if (!loading && !initialMsg) {
      // && (!locations || location.length <= 0)) {
      return "No result";
    } else {
      return "Search";
    }
  };
  const getSelectOptionLabel = option => {
    //label = address, name = sec name, value = prim name
    let landmark = option.landmark ? option.landmark + ", " : "";
    return landmark + option.label + ", " + option.state;
    // return option.value + " -> " + option.name + " // " + option.label;
    // return <div>test</div>;
  };
  const handleSelectChange = (event, type) => {
    reinitializeErrors();
    const name = type;
    var value = event?.id;

    setInputs(values => ({ ...values, [name]: value }));
  };
  const handleChange = event => {
    const name = event?.target?.name;
    var value = event?.target?.value;
    if (name === "date") {
      handleDateChange(event);
    } else if (name === "paxcount") {
      value = parseInt(value);
    }
    setInputs(values => ({ ...values, [name]: value }));
    setInitialMsg(true);
  };

  const getDepartureTimeForSubmission = date => {
    // let currentDateMillisec = new Date(new Date().toDateString()).getTime();
    // let currentTimeMillisecDiff =
    //   new Date().getTime() - new Date(new Date().toDateString()).getTime();
    var selectedDateMilisec = new Date(
      new Date(date).toDateString()
    ).getTime();
    let value = selectedDateMilisec;
    // if (selectedDateMilisec < 12 * 60 * 60 * 1000 + currentDateMillisec)
    //   // + diff;
    //   value = selectedDateMilisec + currentTimeMillisecDiff;
    return value;
  };
  const handleDateChange = event => {
    const val = event.target.value;
    setDate(val);
  };
  const createNewQuery = event => {
    let queryInputs = inputs;
    // {
    //   from: inputs.from,
    //   to: inputs.to,
    //   date: inputs.date,
    //   paxcount: inputs.paxcount
    // };
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      var name = "",
        value = {};
      event.preventDefault();
      if (!queryInputs.from) {
        name = "from";
        value = { required: true };
        setErrors(values => ({ ...values, [name]: value }));
      }
      if (!queryInputs.to) {
        name = "to";
        value = { required: true };
        setErrors(values => ({ ...values, [name]: value }));
      }
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      // setErrors(values => ({ ...values, [name]: value }));
      return;
    }
    let date = getDepartureTimeForSubmission(queryInputs?.date);
    if (date < new Date().getTime()) {
      alert("Departure cannot be in past");
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    } else {
      queryInputs.date = date;
      event.preventDefault();
      coTravelService.createQuery(inputs).subscribe({
        next: response => {
          if (response.status) {
            toast.success(response.message);
            setTravelQuery(response.result);
          }
          setLoading(0);
        },
        error: err => {
          // alert("network error");
          setLoading(0);
          return throwError(() => err);
        },
      });
    }
  };
  const AgeSexString = (travelQuery) => {
    let str = "";
    travelQuery = travelQuery?.travelQuery;
    if (travelQuery?.createdby?.profile?.age) {
      str += travelQuery.createdby.profile.age;
      if (travelQuery?.createdby?.profile?.gender) {
        str += " | " + travelQuery.createdby.profile.gender;
      }
    } else if (travelQuery?.createdby?.profile?.gender) {
      str += travelQuery.createdby.profile.gender;
    }
    return <div>{str}</div>;
  }
  return (
    <div style={{ backgroundColor: "light" }}>
      <section className="search-sec bg-white">
        <div className="container">
          {pageLoading && (
            <div className="my-5 text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {!pageLoading && toBeRedirected && <div className="text-center"><div className="h3 mt-5 text-danger text-center">Phone Number, Age and Gender must be added to raise travel query.
            Please check the same and update if not done yet.</div><div className="btn btn-outline-success" onClick={navigateToProfileUpdate}>Check & Update</div></div>}
          {!pageLoading && !toBeRedirected && user && (user.role !== RolesConstants.OPERATOR) &&
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <Form validated={validated} onSubmit={createNewQuery} noValidate>

                    <div className="row justify-content-center">
                      <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0 mr-2">
                        <div className="form-outline">
                          <Select
                            className={
                              errors?.from?.required
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            options={locations}
                            name="from"
                            placeholder="From"
                            onChange={event => handleSelectChange(event, "from")}
                            onInputChange={searchLocation}
                            noOptionsMessage={event => notFoundMessage()}
                            getOptionLabel={option => getSelectOptionLabel(option)}
                            required
                            onFocus={setInitialValues}
                          />
                        </div>
                      </div>

                      <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0 mr-2">
                        <div data-mdb-input-init className="form-outline">
                          <Select
                            className={
                              errors?.to?.required
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            options={locations}
                            name="to"
                            placeholder="To"
                            onChange={event => handleSelectChange(event, "to")}
                            onInputChange={searchLocation}
                            noOptionsMessage={event => notFoundMessage()}
                            getOptionLabel={option => getSelectOptionLabel(option)}
                          />
                        </div>
                      </div>
                      <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0 mr-2">
                        <div data-mdb-input-init className="form-outline">
                          <input
                            type="date"
                            className="form-control form-control-lg"
                            name="date"
                            placeholder="Departure Time"
                            value={date || ""}
                            onChange={handleChange}
                            required
                          />
                          <label className="col small text-left text-muted text-white">
                            Departure Date
                          </label>
                        </div>
                      </div>
                      <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0 mr-2">
                        <div data-mdb-input-init className="form-outline">
                          <input
                            type="number"
                            className="form-control form-control-lg"
                            name="paxcount"
                            max="10"
                            min="1"
                            placeholder="No. of Travelers"
                            value={inputs.paxcount || ""}
                            onChange={handleChange}
                            required
                          />
                          <label className="col small text-left text-muted">
                            Including you (Min: 1, Max: 10)
                          </label>
                        </div>
                      </div>
                      <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0 mr-2">
                        <Form.Select
                          className="text-capitalize form-select form-select-lg is-1invalid"
                          aria-label="Default select example"
                          id="slot"
                          name="slot"
                          value={inputs.slot || ""}
                          // placeholder="Time Slot"
                          onChange={handleChange}
                          required
                        >
                          <option disabled value={""}>
                            Time Slot
                          </option>
                          {filtersConstants.TimeSlotsArray.map(slot => {
                            return (

                              <option
                                key={slot}
                                value={slot}
                              >
                                {filtersConstants.getTimeSlotString(slot)}
                              </option>
                            );
                          })}
                        </Form.Select>

                      </div>
                      <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0">
                        <div data-mdb-input-init className="text-center 1form-outline">
                          <button
                            type="submit"
                            className="font-weight-bold btn btn-lg app-bg-color text-dark btn-outline-light 1btn-block"
                          // disabled={}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>

                  </Form></div>
              </div>
              {
                travelQuery &&
                <div className="row"><div className="col"><div
                  className="shadow mb-5 card card-stepper bg-image hover-overlay"
                  style={{ borderColor: "lawngreen" }}
                  key={travelQuery._id}
                >
                  <div className="card-body 1p-0">
                    <div className="d-flex flex-column flex-sm-row">
                      <div className="pb-2 col-auto justify-content-between 1border d-flex flex-row border-bottom 1rounded">

                        <div className="d-flex flex-row flex-md-column 1col-4">
                          <div className="col-auto 1mr-2"><img
                            src="/avatar.jpg"
                            alt=""
                            style={{ width: "30px", height: "30px" }}
                            className="rounded-circle"
                          />
                          </div>
                          <div className="col d-flex flex-column"><div className="1py-2">{travelQuery?.createdby?.name}
                          </div>
                            <AgeSexString travelQuery={travelQuery}></AgeSexString>
                          </div>
                        </div>
                        {/* <div className="col text-left"><div className="text-danger font-weight-bold">Along with
                                          </div><div><span className="pr-2 text-success">1 Male
                                          </span><span className="pr-2 text-primary">1 Female
                                              </span><span className="pr-2 text-warning">1 Child
                                              </span></div></div> */}

                      </div>
                      <div className="pb-2 col justify-content-between 1border d-flex flex-row border-bottom 1rounded">
                        <div className="justify-content-left 1border d-flex flex-row 1border-bottom 1rounded">
                          <div className="text-break col-auto d-flex flex-column text-center">
                            <div className="text-capitalize">
                              {travelQuery.from_loc?.city}
                            </div>
                            <div className="text-capitalize text-muted small">
                              {travelQuery.from_loc?.state}
                            </div>
                            <small>{filtersConstants.getTimeSlotString(travelQuery.slot)}
                            </small>
                          </div>

                          <div className="text-break col-auto d-flex flex-column text-center">
                            <div className="text-capitalize">
                              {travelQuery.to_loc?.city}
                            </div>
                            <div className="text-capitalize text-muted small">
                              {travelQuery.to_loc?.state}
                            </div>
                          </div>

                        </div></div>
                    </div>

                  </div>
                </div></div></div>
              }
            </div>
          }

        </div>

      </section>
    </div>
  );
}
