import { useEffect, useState } from "react";



import useService from "../../hooks/useService";
import UserProfileService from "../../Services/UserProfileService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PathConstants from "../../routes/PathConstants";
import FiltersConstants from "../../constants/FiltersConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble, faEdit, faFileEdit, faImage, faPhone, faShare, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import EditOperatorProfileDialog from "../dialogs/EditOperatorProfileDialog";
import AuthUser from "../Common/AuthUser";
import ShareService from "../Share/Services/ShareService";
import ReactDOM from "react-dom/client";
import { toast } from "react-toastify";

export default function OperatorProfile() {
  const navigate = useNavigate();
  var { id } = useParams();
  const [openEditOperatorProfileDialog, setOpenEditOperatorProfileDialog] = useState(false);
  const location = useLocation();
  const [userProfile, setUserProfile] = useState();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [isMyDashboard, setIsMyDashboard] = useState(false);
  const userProfileService = useService(UserProfileService);
  const [dialogParams, setDialogParams] = useState({
    action: ""
  });
  const [socialLink, setSocialLink] = useState("");

  // const [title, setTitle] = useState("");


  const NAME = 'N';
  const ABOUT = 'A';
  const SERVICES = 'S';
  const LOCATIONS = 'L';
  const CONTACT = 'C';
  const IMAGE = 'I';
  useEffect(() => {


    if (location.pathname?.startsWith(PathConstants.DASHBOARD)) {
      setIsMyDashboard(true);
      getMyProfile();
    } else {
      getProfile(id);
    }
  }, [reload]);

  const setSocialShareLink = (profile) => {
    setSocialLink(window.location.protocol + "//" +
      window.location.hostname + "/share/profile/" + profile?.user_id);
  }
  function handleConfirmationFromChild(confirmation, result, msg) {
    if (confirmation) {
      setUserProfile(result);
    }
    setOpenEditOperatorProfileDialog(false);
  }

  const showProfileEditDialog = (action) => {
    setDialogParams({
      action: action,
      user_id: userProfile.user_id
    });
    if (action === NAME) {
      setDialogParams(prevState => ({
        ...prevState,
        brand_name: userProfile.brand_name
      }));
    } else if (action === ABOUT) {
      setDialogParams(prevState => ({
        ...prevState,
        about: userProfile.about
      }));
    } else if (action === SERVICES) {
      setDialogParams(prevState => ({
        ...prevState,
        services: userProfile.services
      }));
    } else if (action === LOCATIONS) {
      setDialogParams(prevState => ({
        ...prevState,
        locations: userProfile.locations
      }));
    } else if (action === CONTACT) {
      setDialogParams(prevState => ({
        ...prevState,
        user: userProfile.user,
        address: userProfile.address,
        current_city: userProfile.current_city,
        state: userProfile.state,
      }));
    } else if (action === IMAGE) {
      setDialogParams(prevState => ({
        ...prevState,
        profile_pic: userProfile.profile_pic
      }));
    }
    setOpenEditOperatorProfileDialog(true);
  }
  const getProfile = (id) => {
    setLoading(true);
    userProfileService.getUserProfile(id).subscribe(res => {
      setUserProfile(res.result);
      setSocialShareLink(res.result);
      setLoading(false);
    });
  };

  const getMyProfile = () => {
    setLoading(true);
    userProfileService.myprofile().subscribe(res => {
      setUserProfile(res.result);
      setSocialShareLink(res.result);
      setLoading(false);
    });
  };
  const changePassword = () => {
    navigate(PathConstants.FORGOT_PASSWORD);
  };
  const reloadUserProfile = () => {
    setUserProfile();
    setReload(!reload); //above use effect will run on change of reload value
  };

  const navigateToVerifyEmail = email => {
    navigate(PathConstants.EMAIL_VERIFY, { state: email, replace: true });
  };
  function getAddress(profile) {
    let address = ""
    if (profile.address) {
      address += profile.address + ", ";
    } if (profile.city) {
      address += profile.city + ", ";
    } if (profile.state) {
      address += profile.state;
    } if (profile.pincode) {
      address += " - " + profile.pincode;
    }
    return address;
  }
  function getSocialDecription() {
    return "Looking for speedy and affordable ride? Visit us. We can make it happen!";
  }
  const socialShare = () => {
    // const domNode = ReactDOM.getElementById('root');

    const domNode = ReactDOM.createRoot(document.getElementById("social-share"));
    domNode.render(<ShareService description={getSocialDecription()} link={socialLink}></ShareService>);
    //  shareService.show();
  }

  function showPhone() {
    let text = "Phone number is not available";
    if (userProfile?.user?.phone) {
      text = userProfile.user.phone;
    }
    toast.success(text, {
      position: "top-center",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      // transition: Bounce,
    });

  }
  return (
    <div className="container">
      <EditOperatorProfileDialog
        open={openEditOperatorProfileDialog}
        inputs={dialogParams}
        sendConfirmationToParent={handleConfirmationFromChild}
      ></EditOperatorProfileDialog>
      <div className="row justify-content-center">
        <div className="mt-2 px-0 1col-10 col-md-10">
          <button
            className="m-2 btn btn-outline-dark"
            onClick={reloadUserProfile}
          >
            Reload
          </button>
          <button
            className="m-2 btn btn-outline-dark"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          {isMyDashboard && <button
            className="m-2 btn btn-outline-dark"
            onClick={() => changePassword()}
          >
            Change Password
          </button>}
          {loading && (
            <div className="my-5 text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {!loading && userProfile && (
            <section className=""
            >
              <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col col-lg-10 mb-4 mb-lg-0">
                    <div className="card mb-3 rounded"
                    >
                      <div className="row g-0">
                        <div className="pb-4 col-sm-4 app-bg-color text-center text-white"
                        >

                          <img src={userProfile?.profile_pic ? userProfile?.profile_pic : "/avatar.jpg"}
                            alt="Avatar" style={{ height: 100, width: 100 }} className="rounded-circle img-fluid my-4" />
                          {isMyDashboard && <div className="btn" onClick={() => showProfileEditDialog(IMAGE)}><FontAwesomeIcon size="xl" icon={faEdit} /></div>}
                          <h5>{userProfile?.brand_name}</h5>
                          <p>Taxi Operator/Agency</p>
                          <div className="d-flex justify-content-center">
                            {isMyDashboard && <div className="btn" onClick={() => showProfileEditDialog(NAME)}><FontAwesomeIcon size="2x" icon={faUserEdit} /></div>}
                            <div className="text-center">
                              <div className="btn btn-outline-none"
                                onClick={socialShare}
                              >
                                <FontAwesomeIcon size="2x" className="px-1" icon={faShare} />
                              </div>
                            </div>
                            <div id="social-share"></div>
                          </div>
                        </div>
                        <div className="col-sm-8">
                          <div className="card-body p-4">

                            <div className="d-flex justify-content-between">
                              <h6>About</h6>
                              <div className="text-right">
                                {isMyDashboard && <div className="btn p-0" onClick={() => showProfileEditDialog(ABOUT)}><FontAwesomeIcon icon={faEdit} /></div>}
                              </div>
                            </div>
                            <hr className="mt-0 mb-4" />
                            <div className="row pt-1">
                              <div className="col-12 mb-3">
                                <p className="text-muted">{userProfile?.about}</p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <h6>Services</h6>
                              <div className="text-right">
                                {isMyDashboard && <div className="btn p-0" onClick={() => showProfileEditDialog(SERVICES)}><FontAwesomeIcon icon={faEdit} /></div>}
                              </div>
                            </div>
                            <hr className="mt-0 mb-4" />
                            <div className="row pt-1">
                              {userProfile?.services?.map(service => {
                                return (
                                  <div className="col-auto mb-3">
                                    <FontAwesomeIcon className="app-text-color pr-2" icon={faCheckDouble} />
                                    <span className="text-muted">
                                      {service}</span>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="d-flex justify-content-between">
                              <h6>Serving Locations</h6>
                              <div className="text-right">
                                {isMyDashboard && <div className="btn p-0" onClick={() => showProfileEditDialog(LOCATIONS)}><FontAwesomeIcon icon={faEdit} /></div>}
                              </div>
                            </div>
                            <hr className="mt-0 mb-4" />
                            <div className="row pt-1">
                              {userProfile?.locations?.map(location => {
                                return (
                                  <div className="col-auto mb-3">
                                    <FontAwesomeIcon className="app-text-color pr-2" icon={faCheckDouble} />
                                    <span className="text-muted">{location}</span>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="d-flex justify-content-between">
                              <h6>Contact Detail</h6>
                              <div className="text-right">
                                {isMyDashboard && <div className="btn p-0" onClick={() => showProfileEditDialog(CONTACT)}><FontAwesomeIcon icon={faEdit} /></div>}
                              </div>
                            </div>

                            <hr className="mt-0 mb-4" />
                            <div className="row pt-1">
                              <div className="col-auto mb-3">
                                <h6>Email</h6>
                                <p className="text-muted">{userProfile.user?.email}</p>
                              </div>
                              <div className="col-auto mb-3">
                                <h6>Address</h6>
                                <p className="text-muted">{getAddress(userProfile)}</p>
                              </div>
                              <div className="col-auto mb-3">
                                {/* Login Required */}
                                {!isMyDashboard && <div onClick={showPhone} className="btn text-center text-white app-bg-color">
                                  <FontAwesomeIcon className="pr-2" icon={faPhone} />Contact</div>
                                }{isMyDashboard && <><h6>Phone</h6>
                                  <p className="text-muted">{userProfile.user?.phone}</p></>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
}
