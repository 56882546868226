import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import UserProfileService from "../../Services/UserProfileService";
import useService from "../../hooks/useService";
import { Form } from "react-bootstrap";

export default function EditOperatorProfileDialog(props) {
  const [inputs, setInputs] = useState({});
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLocation, setImageLocation] = useState("/avatar.jpg");

  const userProfileService = useService(UserProfileService);

  const initMyProfile = () => {
    if (props.inputs) {
      if (props.inputs?.profile_pic) {
        setImageLocation(props.inputs?.profile_pic);
      }
      setInputs(prevState => ({
        ...prevState,
        profile_pic: props.inputs?.profile_pic,
        brand_name: props.inputs?.brand_name,
        about: props.inputs?.about,
        services: props.inputs?.services,
        locations: props.inputs?.locations,

        email: props.inputs?.user?.email,
        phone: props.inputs?.user?.phone,

        current_city: props.inputs?.current_city,
        state: props.inputs?.state,
        // pincode: myProfile.pincode,
        address: props.inputs?.address,
      }));
    }
  };
  useEffect(() => {
    initMyProfile();
    setLoading(false);
  }, [props]);

  const handlePhone = phoneNumber => {
    if (!isValidPhoneNumber(phoneNumber)) {
      return;
    }
  };


  const isValidPhoneNumber = phoneNumber => {
    var phoneRegex = /^\d{10}$/;
    if (phoneNumber && phoneNumber.match(phoneRegex)) {
      console.log("phone valid");
      return true;
    } else return false;
  };

  const updateProfile = event => {

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    } else if (props.inputs?.action === 'N') {

    } else if (props.inputs?.action === 'A') {
    } else if (props.inputs?.action === 'S') {
    } else if (props.inputs?.action === 'L') {
    } else if (props.inputs?.action === 'C') {
      //chek phone
      console.log(inputs.phone);
      if (inputs.phone && !isValidPhoneNumber(inputs.phone)) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        return;
      }
    }
    setLoading(true);
    event.preventDefault();
    setValidated(false);
    userProfileService.post(props.inputs?.user_id, inputs, props.inputs?.action).subscribe({
      next: response => {
        setLoading(false);
        if (response.status) {
          handleConfirm(response.result);
        }
      },
      error: err => {
        setLoading(false);
      },
    });
  };

  const handleServiceLocationChange = (event, index) => {
    const name = event.target.name;
    var value = event.target.value;
    let inputArray, inputName;
    if (name.startsWith('service')) {
      inputArray = inputs.services;
      inputName = "services";
    } else if (name.startsWith('location')) {
      inputArray = inputs.locations;
      inputName = "locations";
    } else return;

    if (inputArray) {
      inputArray[index] = value;
    } else {
      let tempArray = [];
      tempArray[index] = value;
      inputArray = tempArray;
    }
    setInputs(values => ({ ...values, [inputName]: inputArray }));
  }

  const handleChange = event => {

    const name = event.target.name;
    var value = event.target.value;
    if (name === "phone") {
      handlePhone(value);
    } else if (name === "age") {
      value = parseInt(value);
    } else if (name === "profile_pic") {
      console.log(event.target.files);
      // value = event.target.files[0];
      setImageLocation(URL.createObjectURL(event.target.files[0]));
      const formData = new FormData();
      formData.append(
        "profile_pic",
        event.target.files[0]
        //  "profile_pic"
      );
      // console.log(formData.values());
      setInputs(formData);
      // value = event.target.files[0];
      // setInputs(value);
      return;
    }



    setInputs(values => ({ ...values, [name]: value }));
  };


  const handleClose = () => {
    props.sendConfirmationToParent(false);
  };
  const handleConfirm = (result) => {
    props.sendConfirmationToParent(true, result, "Success");
  };

  return (
    <>
      <Modal show={props.open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{"Edit"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form
            onSubmit={updateProfile}
            validated={validated}
            noValidate
          >
            {/* Profile Pic */}
            {(props.inputs?.action === 'I') &&
              <div data-mdb-input-init className="form-outline mb-4 text-center">
                <input
                  type="file"
                  className="form-control form-control-lg"
                  name="profile_pic"
                  placeholder="Profile Pic"
                  // value={inputs.profile_pic || ""}
                  onChange={handleChange}
                  required
                />{" "}
                <img src={imageLocation} alt="Avatar" style={{ height: 100, width: 100 }} className="border border-dark rounded-circle img-fluid my-4" />
              </div>
            }
            {/* Brand Name */}
            {(props.inputs?.action === 'N') &&
              <div data-mdb-input-init className="form-outline mb-4">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="brand_name"
                  placeholder="Brand Name"
                  value={inputs.brand_name || ""}
                  onChange={handleChange}
                  required
                />{" "}
              </div>}
            {/* End Brand Name */}
            {/* About */}
            {(props.inputs?.action === 'A') &&
              <div data-mdb-input-init className="form-outline mb-4">
                <textarea
                  type="textarea"
                  className="form-control form-control-lg"
                  row="3"
                  name="about"
                  placeholder="About You"
                  value={inputs.about || ""}
                  onChange={handleChange}
                  required
                />{" "}
              </div>}
            {/* End About */}
            {/* Services */}
            {(props.inputs?.action === 'S') &&
              [1, 2, 3].map((service, index) => {
                return (
                  <div key={index} data-mdb-input-init className="form-outline mb-4">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      name={"service-" + index}
                      placeholder={"Service " + (index + 1)}
                      value={Array.isArray(inputs.services) ? inputs.services[index] : ""}
                      onChange={(e) => handleServiceLocationChange(e, index)}
                      required
                    />{" "}
                  </div>);
              }
              )
            }
            {/* End Services */}

            {/* Locations */}
            {(props.inputs?.action === 'L') &&
              [1, 2, 3].map((location, index) => {
                return (
                  <div key={index} data-mdb-input-init className="form-outline mb-4">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      name={"location-" + index}
                      placeholder={"Serving Location " + (index + 1)}
                      value={Array.isArray(inputs.locations) ? inputs.locations[index] : ""}
                      onChange={(e) => handleServiceLocationChange(e, index)}
                      required
                    />{" "}
                  </div>);
              }
              )}
            {/* End Locations */}
            {/* Contact Details */}
            {(props.inputs?.action === 'C') &&
              <>
                <div data-mdb-input-init className="form-outline mb-4">
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    name="email"
                    placeholder="Email"
                    value={inputs.email || ""}
                    onChange={handleChange}
                    required
                    disabled
                  />{" "}
                </div>
                <div data-mdb-input-init className="form-outline mb-4">
                  <input
                    type="integer"
                    className="form-control form-control-lg"
                    name="phone"
                    placeholder="Phone"
                    value={inputs.phone || ""}
                    onChange={handleChange}
                    required
                    disabled
                  />{" "}
                </div>
                <div data-mdb-input-init className="form-outline mb-4">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    name="address"
                    placeholder="Address"
                    value={inputs.address || ""}
                    onChange={handleChange}
                    required

                  />{" "}
                </div>
                <div data-mdb-input-init className="form-outline mb-4">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    name="current_city"
                    placeholder="City"
                    value={inputs.current_city || ""}
                    onChange={handleChange}
                    required
                  />{" "}
                </div>
                <div data-mdb-input-init className="form-outline mb-4">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    name="state"
                    placeholder="State"
                    value={inputs.state || ""}
                    onChange={handleChange}
                    required
                  />{" "}
                </div>
              </>}
            {/* End Contact Detail */}
            {loading && (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            )}
            {!loading &&
              <input className="btn btn-block app-bg-color mt-3 btn " type="submit" />
            }
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
